<template>
    <div>
        <RowWrap :class="[{ 'is-empty': mShifts.length === 0 }]">
            <template #header>
                <Row
                    v-if="mShifts.length > 0"
                    :parentHover="false"
                    :items="{ row: header }"
                    :key="-1"
                    :index="-1"
                    @onClick="args => $emit('onHeaderClick', args)"
                    @onClickColumn="handleHeaderClick"
                    class="Row-Header"
                />
            </template>
            <Row
                v-for="(shift, i) in mShifts"
                :items="shift"
                :key="i"
                :index="i"
                :hasBadge="shift.hasBadge"
                :isInitialActive="showAllTrips"
                :isDropdown="shift.children && shift.children.length > 0"
                :hasWarning="hasWarning(shift)"
                :hasError="shift.hasError"
                @onHover="args => $emit('onRowHover', args)"
                @onLeave="args => $emit('onRowLeave', args)"
                @onChildClick="({ index }) => $emit('onTripClick', { i, j: index })"
            />
            <EmptyState v-if="mShifts.length === 0" />
        </RowWrap>
        <Button
            class="Button--LoadMore"
            v-if="mShifts.length >= 30 && mShifts.length < shifts.length"
            @onClick="handleLoadMore"
            >Mehr Laden
        </Button>
    </div>
</template>

<script>
import { format } from 'date-fns';
import Row from '@/components/Row';
import Button from '@/components/widgets/Button';
import { round2d } from '@/lib/helper';
import EmptyState from '@/components/EmptyState.vue';
import RowWrap from '@/components/RowWrap.vue';

export default {
    name: 'ShiftsTable',
    components: {
        RowWrap,
        Row,
        Button,
        EmptyState,
    },
    props: {
        showAllTrips: {
            type: Boolean,
            default: false,
        },
        shifts: {
            type: Array,
        },
    },
    data: () => {
        return {
            drivers: JSON.parse(localStorage.getItem('drivers')),
            cursor: 80,
            sortState: {
                column: 'Abfahrt',
                ascending: false,
            },
        };
    },
    computed: {
        header() {
            return [
                { name: 'Fahrer', value: null, id: 'driver' },
                { name: 'Schicht Nr', value: null, id: 'sequenceNumber' },
                { name: 'Kennzeichen', value: null, id: 'licenseNumber' },
                { name: 'Abfahrt', value: null, id: 'startAt' },
                { name: 'Ankunft', value: null, id: 'endAt' },
                { name: 'Dauer', value: null, id: 'duration' },
                { name: 'Gesamt KM', value: null, id: 'totalDistance' },
                { name: 'Besetzt KM', value: null, id: 'hiredDistance' },
                { name: 'Leer KM', value: null, id: 'forHireDistance' },
                { name: 'Zuschläge', value: null, id: 'finalExtrasAmount' },
                { name: 'Fehlbetrag', value: null, id: 'subtractedTotalAmount' },
                { name: 'Umsatz', value: null, id: 'finalTotalAmount' },
            ].map(header => {
                let indicator = '';
                if (this.sortState.column === header.name) {
                    indicator = this.sortState.ascending ? ' ▲' : ' ▼';
                }
                return { ...header, name: header.name + indicator };
            });
        },
        mShifts() {
            return this.shifts.length > 0
                ? this.sortShifts([...this.formatShifts(this.shifts.slice(0, this.cursor))])
                : [];
        },
    },
    methods: {
        sortShifts(shifts) {
            const { column, ascending } = this.sortState;
            return shifts.sort((a, b) => {
                console.log({ a, b });
                // Use a helper function to get the raw value of a given column
                const getRawValue = shift => {
                    const entry = shift.row.find(item => item.name === column);
                    return entry ? entry.rawValue : undefined; // Fallback to undefined
                };

                const aVal = getRawValue(a);
                const bVal = getRawValue(b);
                // Handle cases where one of the values might be undefined
                if (aVal === undefined && bVal === undefined) return 0;
                if (aVal === undefined) return ascending ? 1 : -1;
                if (bVal === undefined) return ascending ? -1 : 1;

                // Handle different data types
                if (typeof aVal === 'number' && typeof bVal === 'number') {
                    return ascending ? aVal - bVal : bVal - aVal;
                }
                if (aVal instanceof Date && bVal instanceof Date) {
                    return ascending ? aVal - bVal : bVal - aVal;
                }
                if (typeof aVal === 'string' && typeof bVal === 'string') {
                    return ascending ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
                }

                return 0; // Default case for other types
            });
        },
        handleHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();
            if (this.sortState.column === columnName) {
                this.sortState.ascending = !this.sortState.ascending;
            } else {
                this.sortState.column = columnName;
                this.sortState.ascending = true;
            }
        },
        handleDriverNameClick({ uuid }) {
            this.$emit('onDriverClick', uuid);
        },
        hasWarning(entry) {
            return entry.data?.subtractedTotalAmount !== 0 || !entry.data.driver?.name;
        },
        hasError(entry) {
            const driver = this.drivers.find(d => d.id === entry.driverNumber);
            const driverNumberIsName = entry.driver?.name == entry.driver.driverNumber;
            // const hiddenDriverHasRevenue = entry.finalTotalAmount > 0 && !driver?.isVisible;
            return driverNumberIsName;
        },
        hasTripWarning(entry) {
            return entry?.subtractedTotalAmount !== 0;
        },
        handleLoadMore() {
            this.cursor += 80;
        },
        formatShifts(shifts) {
            const s = shifts.map(shift => {
                const hasError = this.hasError(shift);
                return {
                    hasError,
                    hasBadge: hasError,

                    row: [
                        {
                            name: 'Fahrer',
                            value: `<span style="color:${
                                hasError ? 'var(--color-red-light)' : 'inherit'
                            }">${shift.driver?.name}</span>`,
                            props: {
                                tooltip: 'Fahrer Ändern',
                                onClick: () => this.handleDriverNameClick(shift),
                            },
                            rawValue: shift.driver?.name,
                        },
                        {
                            name: 'Schicht Nr',
                            value: shift.sequenceNumber,
                            rawValue: shift.sequenceNumber,
                        },
                        {
                            name: 'Kennzeichen',
                            value: shift.licenseNumber,
                            rawValue: shift.licenseNumber,
                        },
                        {
                            name: 'Abfahrt',
                            value: format(new Date(shift.startAt), 'dd.MM.yyyy HH:mm'),
                            rawValue: new Date(shift.startAt),
                        },
                        {
                            name: 'Ankunft',
                            value: format(new Date(shift.endAt), 'dd.MM.yyyy HH:mm'),
                            rawValue: new Date(shift.endAt),
                        },
                        {
                            name: 'Dauer',
                            value: `${Math.floor(
                                (new Date(shift.endAt) - new Date(shift.startAt)) / 1000 / 60 / 60,
                            )}h ${Math.floor(
                                ((new Date(shift.endAt) - new Date(shift.startAt)) / 1000 / 60) %
                                    60,
                            )}m`,
                            rawValue: new Date(shift.endAt) - new Date(shift.startAt),
                        },

                        {
                            name: 'Gesamt KM',
                            value: shift.totalDistance
                                ? round2d(shift.totalDistance / 1000).format()
                                : '--:--',
                            rawValue: shift.totalDistance / 1000,
                        },
                        {
                            name: 'Besetzt KM',
                            value: shift.hiredDistance
                                ? round2d(shift.hiredDistance / 1000).format()
                                : '--:--',
                            rawValue: shift.hiredDistance / 1000,
                        },
                        {
                            name: 'Leer KM',
                            value: shift.forHireDistance
                                ? round2d(shift.forHireDistance / 1000).format()
                                : '--:--',
                            rawValue: shift.forHireDistance / 1000,
                        },
                        {
                            name: 'Zuschläge',
                            value: shift.finalExtrasAmount
                                ? `${round2d(shift.finalExtrasAmount / 100).format()}€`
                                : '--:--',
                            rawValue: shift.finalExtrasAmount / 100,
                        },
                        {
                            name: 'Fehlbetrag',
                            value: shift.subtractedTotalAmount
                                ? `<span style="color:(var(--color-red-light))">-${round2d(
                                      shift.subtractedTotalAmount / 100,
                                  ).format()}€</span>`
                                : '--:--',
                            rawValue: shift.subtractedTotalAmount / 100,
                        },
                        {
                            name: 'Umsatz',
                            value: `${round2d(shift.finalTotalAmount / 100).format()}€`,
                            rawValue: shift.finalTotalAmount / 100,
                        },
                    ],
                    children: [
                        {
                            row: [
                                { name: 'Schicht Nr', value: null },
                                { name: 'Signal Nr', value: null },
                                { name: 'Abfahrt', value: null },
                                { name: 'Ankunft', value: null },
                                { name: 'Gesamt KM', value: null },
                                { name: 'Besetzt KM', value: null },
                                { name: 'Leer KM', value: null },
                                { name: 'Zuschläge', value: null },
                                { name: 'Fehlbetrag', value: null },
                                { name: 'Umsatz', value: null },
                            ],
                        },
                        ...shift?.trips
                            ?.sort((a, b) => {
                                if (a.sequenceNumber < b.sequenceNumber) {
                                    return 1;
                                }
                                if (a.sequenceNumber > b.sequenceNumber) {
                                    return -1;
                                }
                                return 0;
                            })
                            .map?.(trip => {
                                const hasWarning = this.hasTripWarning(trip);
                                return {
                                    hasBadge: hasWarning,
                                    row: [
                                        { name: 'Schicht Nr', value: trip.shiftSequenceNumber },
                                        { name: 'Signal Nr', value: trip.sequenceNumber },
                                        {
                                            name: 'Abfahrt',
                                            value: format(
                                                new Date(trip.startAt),
                                                'dd.MM.yyyy HH:mm',
                                            ),
                                        },
                                        {
                                            name: 'Ankunft',
                                            value: format(new Date(trip.endAt), 'dd.MM.yyyy HH:mm'),
                                        },
                                        {
                                            name: 'Gesamt KM',
                                            value: round2d(trip.totalDistance / 1000).format(),
                                        },
                                        {
                                            name: 'Besetzt KM',
                                            value: round2d(trip.hiredDistance / 1000).format(),
                                        },
                                        {
                                            name: 'Leer KM',
                                            value: round2d(trip.onCallDistance / 1000).format(),
                                        },
                                        {
                                            name: 'Zuschläge',
                                            value:
                                                round2d(trip.finalExtrasAmount / 100).format() +
                                                '€',
                                        },

                                        {
                                            name: 'Fehlbetrag',
                                            value: `<span style="color:${
                                                hasWarning ? 'var(--color-red-light)' : 'inherit'
                                            }">${hasWarning ? '-' : ''}${round2d(
                                                trip.subtractedTotalAmount / 100,
                                            ).format() + '€'}</span>`,
                                        },
                                        {
                                            name: 'Umsatz',
                                            value: `<span style="color:${
                                                hasWarning ? 'var(--color-orange)' : 'inherit'
                                            }">${round2d(trip.finalTotalAmount / 100).format() +
                                                '€'}</span>`,
                                        },
                                    ],
                                    props: {
                                        hasWarning: this.hasTripWarning(shift) ? hasWarning : false,
                                    },
                                    data: trip,
                                };
                            }),
                    ],
                    data: shift,
                };
            });
            return s;
        },
    },
};
</script>
